import logo from './static/logo.png';
import style from './App.module.scss';

const year = new Date().getUTCFullYear()

function App() {
  return (
    <div>
      <header className={style.header}>
        <img src={logo} className={style.logo} alt="Vinir Vatnsendahvarfs" />
      </header>
      <div className={style.app}>
        <div className={style.content}>
          <h2 className={style.title}>
            Vinir Vatnsendahvarfs eru óformleg samtök íbúa sem vilja stuðla að verndun náttúru og lífríkis Vatnsendahvarfs
          </h2>
          <p>
            Vatnsendahvarf, sem einnig er þekkt sem Vatnsendahæð, er gróðursæl útivistarperla sem liggur á mörkum Reykjavíkur og Kópavogs með útsýni til allra átta. Fjöldi fólks nýtir sér hæðina daglega til útivista, sem og að njóta útsýnisins.
          </p>
          <p>
            Fyrirhugaðar vegaframkvæmdir 3. kafla Arnarnesvegar, sem eiga að hefjast árið 2021 munu skera hæðina í tvennt og breyta algjörlega ásýnd og notagildi svæðisins til frambúðar. Umhverfismat sem stuðst er við til að réttlæta framkvæmdina er frá 2003.
          </p>
          <p>
            Vatnsendahvarfið er mun grónara en fyrir 18 árum síðan og virði þess sem útivistarsvæðis mun meira. Þar vex fjölbreyttur gróður og ýmsar tegundir farfugla, eins og lóur, hrossagaukar og spóar, verpa þar á hverju ári. Mikið rask yrði af framkvæmdunum ásamt stórfelldri eyðileggingu á dýrmætu grænu svæði og villtri náttúru.
          </p>
          <p>
            Vinir Vatnsendahvarfs telja að framkvæmdin muni hafa verulega neikvæð áhrif á umhverfi, hljóðvist og útivist á svæðinu. Vegurinn eins og hann er skipulagður í dag er ekkert annað stórfelld og óafturkræf skipulagsmistök og landníðsla. Því teljum við mikla þörf á endurgerð umhverfismatsins, sem er nær tveggja áratuga gamalt, og algjörri endurskoðun á þessari vegalagningu með heildarmyndina í huga. Mikið hefur breyst varðandi áherslur í umhverfismálum og samgöngumátum á þessum áratugum sem nauðsynlegt er að taka með inn í myndina.
          </p>
        </div>
        <h2>
          Fréttir og tenglar
        </h2>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/groups/1368537469961320"
            >
              Vinir Vatnsendahvarfs á Facebook
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vatnsendahvarf.is/static/athugasemdir-10-03-2022.pdf"
            >
              Athugasemdir vegna tillögu að deiliskipulagi Arnarnesvegar - 10. mars 2022
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vatnsendahvarf.is/static/Kaera26052021_web.pdf"
            >
              Kæra til Úrskurðarnefndar umhverfis- og auðlindamála - 26 maí 2021
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.frettabladid.is/skodun/er-rett-ad-forna-vatnsendahvarfi-fyrir-arnarnesveg/"
            >
              Er rétt að fórna Vatnsendahvarfi fyrir Arnarnesveg - Grein í Fréttablaðinu - 11. nóvember 2020
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vatnsendahvarf.is/static/AthugasemdirArnarnesvegur_Kop_VV.pdf"
            >
              Athugasemdir til skipulagsdeildar Kópavogs vegna 3. áfanga Arnarnesvegar
            </a>
          </li>

          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vatnsendahvarf.is/static/Athugasemdir_deiliskipulagVatnsendahvarfs_VV.pdf"
            >
              Athugasemdir til skipulagsdeildar Kópavogs vegna nýs deiliskipulags Vatnsendahvarfs
            </a>
          </li>

          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vatnsendahvarf.is/static/umsogn.pdf"
            >
              Umsögn til Skipulagsstofnunar frá Vinum Vatnsendahvarfs (PDF)
            </a>
          </li>

          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vatnsendahvarf.is/static/landvernd.pdf"
            >
              Umsögn til Skipulagsstofnunar frá Landvernd (PDF)
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://vatnsendahvarf.is/static/athugasemdir.pdf"
            >
              Athugasemdir við breytingar á Aðalskipulagi Reykjavíkur frá Vinum Vatnsendavarfs (PDF)
            </a>
          </li>
        </ul>
        <p className={style.email}><a href="mailto:vatnsendahvarf@gmail.com">Hafa samband</a></p>
        <div className={style.footer}>
          <p><span>&copy;</span> {year} Vinir Vatnsendahvarfs</p>
        </div>
      </div>
    </div>
  );
}

export default App;
